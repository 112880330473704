import PropTypes from 'prop-types';
import TopNavSearchForm from './TopNavSearchForm';
import MobileMenu from './MobileMenu';
import cx from 'classnames';
import { triggerModalFromClickEvent } from './../shared/utils/modalUtil';
import get from 'lodash.get';
import UserAvatar from './UserAvatar';
import './TopNavHeader.scss';
import { debounce } from '../shared/utils/helpers';
import trackEvent from '../../scripts/analytics';

const i18n = {
  logoAltText: 'App Marketplace Logo',
  buildAnApp: 'Build an App',
  submitAnIdea: 'Submit an Idea',
  faq: 'FAQ',
  becomeAPartner: 'Become a Partner',
  webinars: 'Webinars',
  login: 'Log In',
  community: 'Community',
};

const sendAnalytics = (
    action,
    category,
    label,
    procoreLabel,
    procoreAdditionalFields,
    gtagValue
  ) => {
    if (typeof gtag !== 'undefined') {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        gtagValue,
      });
    }
    trackEvent(`marketplace.apps.${procoreLabel}`, {
      app_name: name,
      ...procoreAdditionalFields,
    });
  };

class TopNavHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSolid: props.isSolid,
      searchOpacity: props.searchOpacity
    };

    this.handleScroll = debounce(this.handleScroll.bind(this), 50);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (this.props.animateOnScroll) {
      const yOffset = window.pageYOffset || document.documentElement.scrollTop;

      this.setState({
        isSolid: yOffset >= 1
      })
    }
  }

  isLinkActive(path) {
    const href = get(window, ['location', 'href'], '');
    return href.indexOf(path) > -1;
  }

  fallBackUrl = () => {
    const pathRegex = /apps\S+/;

    return pathRegex.exec(window.location.href)
      ? window.location.href
      : null;
  };

  signOutWithFallbackPath = () => {
    const { signOutPath } = this.props
    const fallBack = this.fallBackUrl();

    return fallBack
      ? `${signOutPath}?fallback=${this.fallBackUrl()}`
      : signOutPath;
  };


  render() {
    const {
      isSignedIn,
      currentUser,
      currentCompany,
      marketplaceLogoURL,
      searchPath,
      marketplaceBasePath,
      procoreBasePath,
      appSearchResults,
      communityPath,
      webinarsPath,
      signInPath,
      changeCompanyPath,
      faqsPath,
      buildAppPath,
      partnerSignupPath,
      appsBasePath,
      appsSearchApiPath,
      categories,
      isHeroSearchVisible,
      searchDataState,
      searchTerm,
      setSearchTerm,
      fuse,
    } = this.props;

    const headerClasses = cx('pc-mp-header', 'pc-mp-header--pc-mp-large-header', {'solid-header': this.state.isSolid || this.state.searchOpacity === 1 });

    return (
      <header className={headerClasses}>
        <div className='pc-mp-header--container'>
          <div className='logo'>
            <a href={marketplaceBasePath}>
              <img src={marketplaceLogoURL}  alt={i18n.logoAltText} id='procore-logo' className='desktop-logo white-logo'/>
            </a>
          </div>
          <div className='header-search-container'>
            {
              !isHeroSearchVisible &&
                <TopNavSearchForm
                  searchPath={searchPath}
                  appSearchResults={appSearchResults}
                  buildAppPath={buildAppPath}
                  appsSearchApiPath={appsSearchApiPath}
                  openIdeaSubmitModal={triggerModalFromClickEvent}
                  searchDataState={searchDataState}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  fuse={fuse}
                />
            }
          </div>
          <div className='main-nav-links'>
            <a href={communityPath}
               target="_blank"
               onClick={() =>
                              sendAnalytics(
                                'click',
                                'Link',
                                'Community - Header',
                                'community_header.clicked'
                              )
                            }
                >{i18n.community}</a>
            <a href={webinarsPath}>{i18n.webinars}</a>
            <a href={faqsPath} className={cx('main-nav-link-faq', {'active': this.isLinkActive(faqsPath)})}>
              {i18n.faq}
            </a>
            <a href={partnerSignupPath}>{i18n.becomeAPartner}</a>
            <a href={buildAppPath} className={cx('main-nav-link-build', {'active': this.isLinkActive(buildAppPath)})}>
              {i18n.buildAnApp}
            </a>
            <a href='#submit-idea' onClick={triggerModalFromClickEvent}>{i18n.submitAnIdea}</a>
            {
              !isSignedIn ? (
                <a
                  data-qa="main-nav-login-button"
                  href={signInPath} className={cx('main-nav-link-build')}>
                  {i18n.login}
                </a>
              ) : (
                <UserAvatar
                  signOutPath={this.signOutWithFallbackPath()}
                  changeCompanyPath={changeCompanyPath}
                  email={currentUser.email}
                  company={currentCompany.name}
                />
              )
            }
          </div>
          <MobileMenu appsBasePath={appsBasePath}
            isSignedIn={isSignedIn}
            signInPath={signInPath}
            signOutPath={this.signOutWithFallbackPath()}
            changeCompanyPath={changeCompanyPath}
            currentUser={currentUser}
            communityPath={communityPath}
            webinarsPath={webinarsPath}
            faqsPath={faqsPath}
            partnerSignupPath={partnerSignupPath}
            buildAppPath={buildAppPath}
            categories={categories}/>
        </div>
      </header>
    );
  }
}

TopNavHeader.propTypes = {
  signInPath: PropTypes.string.isRequired,
  signOutPath: PropTypes.string.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    procore_id: PropTypes.number,
  }).isRequired,
  marketplaceBasePath: PropTypes.string.isRequired,
  procoreBasePath: PropTypes.string.isRequired,
  appsBasePath: PropTypes.string.isRequired,
  partnerSignupPath: PropTypes.string.isRequired,
  communityPath: PropTypes.string.isRequired,
  webinarsPath: PropTypes.string.isRequired,
  faqsPath: PropTypes.string.isRequired,
  buildAppPath: PropTypes.string.isRequired,
  appSearchResults: PropTypes.array.isRequired,
  searchPath: PropTypes.string.isRequired,
  appsSearchApiPath: PropTypes.string.isRequired,
  marketplaceLogoURL: PropTypes.string.isRequired,
  animateOnScroll: PropTypes.bool,
  isSolid: PropTypes.bool,
  searchOpacity: PropTypes.number,
  categories: PropTypes.array.isRequired
};

export default TopNavHeader;
