import {
  Button,
  H3,
  Modal,
  P
} from '@procore/core-react';
import PT from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import * as Styles from '../Listings/ListingsShowStyles';

const Container = styled.div`
  margin: 14px 0;
`
const Link = styled(Styles.OrangeLink)`
  text-decoration: none;
`
const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

function VersionHistory({history, showingVersion})  {
  const [open, setOpen] = React.useState(showingVersion);

  if(!Boolean(Array.isArray(history) && history.length) || history.every(v => v.changelog === null)) { return <></>; }

  const changelog = showingVersion ?
    history.filter((version) => version.semantic_version === showingVersion) :
    [...history].reverse();

  const [{semantic_version}] = changelog;
  const VERSION_HISTORY = 'Version History'

  return (
    <>
      <H3>Version {semantic_version}</H3>
      <Container>
        <Link onClick={() => setOpen(true)}>{VERSION_HISTORY}</Link>
      </Container>
      <Modal
        onClickOverlay={() => setOpen(false)}
        open={open}
        width='md'
      >
        <Modal.Header onClose={() => setOpen(false)}>{VERSION_HISTORY}</Modal.Header>
        <Modal.Body>
          {changelog.map((version, idx) => (
            <React.Fragment key={idx + 1}>
              <SectionHeader>
                <H3>{version.semantic_version}</H3>
                <span>{version.created_at}</span>
              </SectionHeader>
              <P>{version.changelog}</P>
            </React.Fragment>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Modal.FooterButtons>
            <Button variant="primary" onClick={() => setOpen(false)}>
              Close
            </Button>
          </Modal.FooterButtons>
        </Modal.Footer>
      </Modal>
    </>
  );
}

VersionHistory.propTypes = {
  history: PT.arrayOf(
    PT.shape({
      changelog: PT.string,
      created_at: PT.string,
      semantic_version: PT.string,
    })
  )
}

VersionHistory.defaultProps = {
  history: [{changelog: null, created_at: '', semantic_version: ''}]
}

export default VersionHistory
