import React, { useEffect, useState } from 'react';
import { Box, Flex, Link, Typography } from '@procore/core-react';
import PT from 'prop-types';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import axios from 'axios';
import { FUSE_CONFIG } from '@/react/shared/utils/fuseConfig';
import TopNavSearchForm from '../topNavBar/TopNavSearchForm';
import TopNavHeader from '../topNavBar/TopNavHeader';
import useVisibility from '../shared/utils/useVisibility';

const Hero = styled(Flex)`
  background: url(/images/marketplace_header.png) no-repeat top center;
  background-size: cover;
  height: 400px;
`;

const MarketplaceIndex = ({ headerProps }) => {
  const [isHeroSearchVisible, heroSearchElement] = useVisibility(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [fuse, setFuse] = useState({
    search: (term) => setSearchTerm(term),
  });
  const [searchDataState, setSearchDataState] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    axios.get('/search_data').then((res) => {
      res.data.filter(app => app.name.startsWith("Arcoro")).map(app =>
        app.search_description = app.search_description.replace(/^/, 'HR ')
      )
      setSearchDataState({
        loading: false,
        data: res.data,
      });
      setFuse(new Fuse(res.data, FUSE_CONFIG));
    });
  }, []);

  return (
    <>
      <TopNavHeader
        isHeroSearchVisible={isHeroSearchVisible}
        fuse={fuse}
        searchDataState={searchDataState}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        {...headerProps}
      />
      <Hero padding="xl" justifyContent="center" alignItems="center">
        <Flex justifyContent="center" alignItems="center" direction="column" maxWidth="100%">
          <Box paddingBottom="lg" marginBottom="xl">
            <Typography
              color="gray98"
              weight="bold"
              style={{ fontSize: '34px', lineHeight: '34px' }}
            >
              {'Explore integrations for Procore'}
            </Typography>
          </Box>
          <Box ref={heroSearchElement} style={{width: "100%", minWidth: "370px", maxWidth: "500px"}}>
            {isHeroSearchVisible && (
              <TopNavSearchForm
                appsSearchApiPath="/search_data"
                fuse={fuse}
                searchDataState={searchDataState}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            )}
          </Box>
        </Flex>
      </Hero>
    </>
  );
};

MarketplaceIndex.propTypes = {
  headerProps: PT.shape({}).isRequired,
};

export default MarketplaceIndex;
