import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import { FUSE_CONFIG } from '@/react/shared/utils/fuseConfig';
import trackEvent from '@/scripts/analytics';
import {
  Box,
  Card,
  colors,
  Flex,
  Link,
  Menu,
  Search,
  P,
  Typography,
} from '@procore/core-react';
import classNames from 'classnames/bind';
import { debounce } from '@/react/shared/utils/helpers';
import axios from 'axios';
import styles from './styles.module.scss';
import { highlight } from './highlightUtil';
import styled from 'styled-components';

const cx = classNames.bind(styles);

const i18n = {
  logoAltText: 'App Marketplace Logo',
  noAppsFound: 'No apps found...',
  searching: 'Searching...',
  searchError: 'Error retrieving search data',
  searchLoading: 'Loading...',
  searchPlaceholder: 'Search for apps',
};

const RESULTS_LIMIT = 7;

const StyledItem = styled(Menu.Item)`
  background-color: ${colors.gray96};
  border-bottom: 1px solid ${colors.gray85};

  &:hover {
    background-color: ${colors.gray90};
  }
`;

const AppResults = ({ apps, searchTerm }) => {
  const limitedResults = highlight(apps.slice(0, RESULTS_LIMIT));

  return limitedResults.map((result, idx, arr) => {
    const { highlightedItem: app } = result;
    const viewAll = arr.length > 1 && idx === arr.length - 1;

    return (
      <>
        <Menu.Item key={app.slug} className={cx(styles.searchResultsItem)}>
          <Link href={`/apps/${app.slug}`}>
            <Flex
              alignItems="center"
              className={cx(styles.searchResultsItemInner)}
            >
              <Flex alignItems="center" justifyContent="center">
                <img
                  alt={`Logo for ${app.name}`}
                  src={app.small_logo_url}
                  className={cx(styles.searchResultsThumbnail)}
                />
              </Flex>
              <Flex paddingLeft="md" direction="column">
                <Typography>{app.name}</Typography>
                <Typography color="gray45">{app.search_description}</Typography>
              </Flex>
            </Flex>
          </Link>
        </Menu.Item>
        {viewAll && (
          <StyledItem key={searchTerm}>
            <Link href={`/search_results?query=${searchTerm}`}>
              <Flex
                alignItems="center"
                className={cx(styles.searchResultsItemInner)}
              >
                <Typography>View All Results</Typography>
              </Flex>
            </Link>
          </StyledItem>
        )}
      </>
    );
  });
};

const handleSearchAnalytics = (searchTerm, count) =>
  trackEvent('marketplace.apps.search.input', {
    marketplace_search_term: searchTerm,
    marketplace_search_count: count,
  });

const debounceHandleSearchAnalytics = debounce(handleSearchAnalytics, 500);
const StyledSearch = styled(Search)`
  width: 100%;
`;

const TopNavSearchForm = ({
  appsSearchApiPath,
  fuse,
  searchDataState,
  searchTerm,
  setSearchTerm,
}) => {
  // Default for pages not yet migrated under SPA architecture
  if (!searchTerm && !setSearchTerm) {
    [searchTerm, setSearchTerm] = useState('');
  }

  const [state, setState] = useState({
    appSearchResults: [],
    showResultsMenu: 'hidden',
  });

  const handleSetState = (newState) =>
    setState({
      ...state,
      ...newState,
    });

  useEffect(() => {
    if (!fuse) {
      axios.get(appsSearchApiPath).then((res) => {
        const newState = {
          fuse: new Fuse(res.data, FUSE_CONFIG),
        };
        if (searchTerm) {
          newState.appSearchResults = newState.fuse.search(searchTerm);
        }
        handleSetState(newState);
      });
    }
  }, []);

  useEffect(() => {
    if (searchTerm.length > 2) {
      handleSetState({
        appSearchResults: fuse
          ? fuse.search(searchTerm)
          : state.fuse.search(searchTerm),
      });
      debounceHandleSearchAnalytics(searchTerm, state.appSearchResults.length);
    } else {
      handleSetState({
        appSearchResults: [],
      });
    }
  }, [searchTerm]);

  const placeholderText = () => {
    if (!searchDataState) {
      return i18n.searchPlaceholder;
    }
    if (searchDataState?.loading) {
      return i18n.searchLoading;
    }
    if (!searchDataState?.loading && !searchDataState?.data.length) {
      return i18n.searchError;
    }
    return i18n.searchPlaceholder;
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      className={cx(styles.searchWrapper)}
    >
      <StyledSearch
        autoComplete="off"
        id="hero_search_input"
        className={cx(styles.searchInput)}
        disabled={
          searchDataState &&
          (searchDataState.loading ||
            (!searchDataState.loading && !searchDataState.data.length))
        }
        placeholder={placeholderText()}
        value={searchTerm}
        onFocus={() =>
          searchTerm.length > 2 &&
          handleSetState({ showResultsMenu: 'visible' })
        }
        onBlur={() => handleSetState({ showResultsMenu: 'hidden' })}
        onChange={(e) => {
          const val = e.target.value;
          setSearchTerm(val);
          handleSetState({
            showResultsMenu: val.length > 2 ? 'visible' : 'hidden',
          });
        }}
        onSubmit={() =>
          (window.location.href = `/search_results?query=${searchTerm}`)
        }
      />
      <Box
        className={cx(
          `${
            state.showResultsMenu === 'visible'
              ? styles.searchResultsVisible
              : styles.searchResultsHidden
          }`,
          styles.searchResultsWrapper
        )}
      >
        <Card>
          <Menu
            className={cx(styles.searchResultsMenu)}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Menu.Options className={cx(styles.searchResultsMenuOptions)}>
              {state.appSearchResults?.length === 0 ? (
                <P className={cx(styles.searchResultsText)}>
                  {state.search ? i18n.searching : i18n.noAppsFound}
                </P>
              ) : (
                <AppResults
                  apps={state.appSearchResults}
                  searchTerm={searchTerm}
                />
              )}
            </Menu.Options>
          </Menu>
        </Card>
      </Box>
    </Flex>
  );
};

TopNavSearchForm.propTypes = {
  appsSearchApiPath: PropTypes.string.isRequired,
};

export default TopNavSearchForm;
