import React from 'react';
import PropTypes from 'prop-types';
import { triggerModalFromClickEvent } from './../shared/utils/modalUtil';

const i18n = {
  login: 'Login',
  logout: 'Logout',
  changeCompany: 'Change Company'
};

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  getLinkPath(appsBasePath, paramKey, paramVal) {
    return `${appsBasePath}?${paramKey}=${paramVal}`;
  };

  getCategoryPath(category) {
    return `/${category.type === 'region' ? 'regions' : 'categories'}/${category.slug}`;
  };

  getMobileLink(href, text) {
    return(
      <div key={`${href}-${text}`} className='pc-mp-mobile-menu-link'>
        <a href={href}>{text}</a>
      </div>
    );
  }

  getCategoriesMobileLinks(appsBasePath) {
    const links = [this.getMobileLink(appsBasePath, 'All')];
    this.props.categories.forEach(category => {
      links.push(this.getMobileLink(this.getCategoryPath(category), category.name))
    });
    return links;
  };

  render() {
    const {
      appsBasePath,
      faqsPath,
      partnerSignupPath,
      buildAppPath,
      communityPath,
      webinarsPath,
      signInPath,
      signOutPath,
      changeCompanyPath,
      isSignedIn,
    } = this.props;

    return (
      <div className='mobile-menu-container'>
        <div className='pc-mp-header--menu-button-container'>
          <span className='pc-mp-mobile-menu-button'><i className='fa fa-bars'></i>Menu</span>
        </div>
        <div className='pc-mp-header--menu-sections-container'>
          <div className='pc-mp-mobile-menu-section'>
            <h6 className='pc-mp-mobile-menu-section-title'>Featured</h6>
            <div className='pc-mp-mobile-menu-link apps_new'><a href={this.getLinkPath(appsBasePath, 'new_app', 'true')}>New</a></div>
          </div>
          <hr className='dash'/>
          <div className='pc-mp-mobile-menu-section'>
            <h6 className='pc-mp-mobile-menu-section-title'>Categories</h6>
            {this.getCategoriesMobileLinks(appsBasePath)}
          </div>

          <hr className='dash'/>
           <div className='pc-mp-mobile-menu-section'>
            <h6 className='pc-mp-mobile-menu-section-title'>Other Information</h6>
            {this.getMobileLink(communityPath, 'Community')}
            {this.getMobileLink(webinarsPath, 'Webinars')}
            {this.getMobileLink(faqsPath, 'FAQ')}
            {this.getMobileLink(partnerSignupPath, 'Become a Partner')}
            {this.getMobileLink(buildAppPath, 'Build an App/Integration')}
            <div className='pc-mp-mobile-menu-link'>
              <a href='#submit-idea' onClick={triggerModalFromClickEvent}>Submit an Idea</a>
            </div>
            {
              !isSignedIn ? (
                this.getMobileLink(signInPath, i18n.login)
              ) : (
                <>
                  {this.getMobileLink(signOutPath, i18n.logout)}
                  {this.getMobileLink(changeCompanyPath, i18n.changeCompany)}
                </>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

MobileMenu.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    procore_id: PropTypes.number,
  }),
  signInPath: PropTypes.string.isRequired,
  signOutPath: PropTypes.string.isRequired,
  appsBasePath: PropTypes.string.isRequired,
  faqsPath: PropTypes.string.isRequired,
  partnerSignupPath: PropTypes.string.isRequired,
  buildAppPath: PropTypes.string.isRequired,
  communityPath: PropTypes.string.isRequired,
  webinarsPath: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired
};

export default MobileMenu;
