import { Box, Flex, Link, Modal } from '@procore/core-react';
import styled, { css } from 'styled-components';

const SmallLogoWrapper = styled(Flex)`
  width: 75px;
  height: 75px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  padding: 10px;
`;

const SmallLogo = styled.img`
  width: 100%;
  height: auto;
`;

const Container = styled(Box)`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 45px);
`;

const Slide = styled.img`
  object-fit: contain;
`;

const SlickWrapper = styled(Box)`
  .slick-arrow {
    transition: opacity 0.3s;
    opacity: 0;

    &:before {
      color: #1d3243;
    }
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
    }
  }
`;

const arrows = css`
  background: #1d3243;
  width: 50px;
  height: 75px;
  padding: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: none;
  }

  &:hover {
    background: #1d3243;
  }
`;

const SlickArrowPrev = styled(Box)`
  ${arrows}
  transform: translate(-20px, -50%);
`;

const SlickArrowNext = styled(Box)`
  ${arrows}
  transform: translate(20px, -50%);
`;

const OrangeLink = styled(Link)`
  color: #f47e42;
`;

const ImageModal = styled(Modal)`
  background: transparent;
  box-shadow: none;
  overflow: visible;
  > div {
    overflow: visible;
  }
`;

const ModalImageBody = styled(Box)`
  width: 90vw;
  height: 90vh;
  max-width: none;
  justify-content: center;
  overflow: visible;
  position: relative;
  display: flex;

  > div > div {
    max-height: none;
  }
`;

const ModalImage = styled.img`
  object-fit: contain;
  box-shadow: 0px 0px 32px -4px hsl(200deg 10% 15% / 64%);
  max-width: 100%;
  max-height: 100%;
`;

const CloseImageModalButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #000;
  border: 2px solid #fff;

  &:after {
    content: '\\00d7';
    color: #fff;
  }
`;

export {
  Container,
  SmallLogo,
  SmallLogoWrapper,
  SlickArrowNext,
  SlickArrowPrev,
  SlickWrapper,
  Slide,
  OrangeLink,
  ImageModal,
  ModalImageBody,
  ModalImage,
  CloseImageModalButton,
};
