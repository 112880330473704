import React from 'react';

const wistiaExtract = (html, setParsedHTML) => {
  const container = document.createElement('div');
  container.innerHTML = html.trim();
  document.body.appendChild(container);

  /**
   * Wistia script and iframe are always first items of HTML
   * so they render at top of page
   */

  const wistiaIframe = container.getElementsByTagName('iframe').item(0);
  const wistiaScript = container.getElementsByTagName('script').item(0);

  /**
   * If no script or iframe, we remove the fake container
   * and set the raw HTML as is
   */
  if (!wistiaScript && !wistiaIframe) {
    document.body.removeChild(container);
    setParsedHTML({
      description: html,
      video: null,
    });
    return;
  }

  let url;

  /**
   * Some videos are embedded via iframe, others embedded via script
   */

  if (wistiaIframe) {
    url = wistiaIframe.src;
    wistiaIframe.parentElement.removeChild(wistiaIframe);
  } else {
    url = wistiaScript.src;
  }

  /**
   * Below we generate the scripts and DOM necessary to allow
   * for the video to be inserted into the media slider
   */

  const wistiaDom = container.getElementsByClassName(
    'wistia_responsive_padding'
  );
  container.removeChild(wistiaDom.item(0));
  document.body.removeChild(container);

  const id = url
    .substring(url.lastIndexOf('/') + 1)
    .split('.')[0]
    .split('?')[0];

  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = `https://fast.wistia.com/embed/medias/${id}.jsonp`;
  s.async = true;

  const s1 = document.createElement('script');
  s1.type = 'text/javascript';
  s1.src = 'https://fast.wistia.com/assets/external/E-v1.js';
  s1.async = true;

  document.head.append(s);
  document.head.append(s1);

  setParsedHTML({
    video: (
      <>
        <div
          className={`wistia_embed wistia_async_${id} videoFoam=true`}
          style={{
            height: '360px',
            width: '640px',
            zIndex: 1,
            position: 'relative',
          }}
        >
          <div
            className="wistia_swatch"
            style={{
              height: '100%',
              left: 0,
              opacity: 0,
              overflow: 'hidden',
              position: 'absolute',
              top: 0,
              transition: 'opacity 200ms',
              width: '100%',
            }}
          >
            <img
              src={`https://fast.wistia.com/embed/medias/${id}/swatch`}
              style={{
                filter: 'blur(5px)',
                height: '100%',
                objectFit: 'contain',
                width: '100%',
              }}
              alt=""
              aria-hidden="true"
            />
          </div>
        </div>
      </>
    ),
    description: container.outerHTML,
  });
};

export default wistiaExtract;
