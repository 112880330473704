import React from 'react';
import { H3, P, Typography, colors } from '@procore/core-react';
import { Plus } from '@procore/core-icons/dist';
import styled from 'styled-components';
import { OrangeLink } from '../ListingsShowStyles';

const StyledLI = styled.li`
  margin: 10px 0 10px -45px;
`;

const StyledH3 = styled(H3)`
  margin: 30px 0;
`

const StyledPlus = styled(Plus)`
  color: ${colors.orange50};
  margin-bottom: -0.5em;
  margin-right: 5px;
`;

const NoResults = () => {
  return (
    <>
      <StyledH3>No results</StyledH3>
      <P>Sorry, we did not find any results for your search.</P>

      <StyledH3>Might we suggest:</StyledH3>

      <Typography as="ul" style={{listStyleType: 'none'}}>
        <StyledLI>
          <StyledPlus />Browsing our app categories to find what you may be looking for
        </StyledLI>
        <StyledLI>
          <StyledPlus />Visiting the&nbsp;
          <OrangeLink
            href={'https://community.procore.com/s/topic/0TO2T000000c9HTWAY/integrations-app-marketplace'}
            target='_blank'>
              Procore Community: Integrations / App Marketplace
          </OrangeLink> and discuss your needs with others
        </StyledLI>
        <StyledLI>
          <StyledPlus />
          <OrangeLink
            href={'https://marketplace.procore.com/#submit-idea'}
            target='_blank'>
              Making a suggestion
          </OrangeLink> so we can start working on delivering your app needs
        </StyledLI>
      </Typography>
    </>
  );
};

export default NoResults;
