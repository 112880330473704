import styled from 'styled-components';
import {
  Link
} from '@procore/core-react';

export const UnstyledLink = styled(Link)`
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;
