export const ADDITIONAL_CATEGORY_LINKS = [{
  href: '/',
  name: 'Featured',
  slug: '/',
}, {
  href: '/featured/new',
  name: 'New',
  slug: 'new',
}, {
  href: '/listings',
  name: 'All',
  slug: 'listings',
}];

export const COMPONENT_MAP = {
  iframe: 'Embedded',
  fullscreen: 'Embedded',
  sidepanel: 'Embedded',
};
