export function highlight(fuseSearchResult, highlightClass = 'highlight', truncLength = 40) {
  const generateHighlightedText = (inputText, regions = []) => {
    let nextUnhighlightedRegionStartingIndex = 0;
    let currStringLen = 0;
    let truncated = false;

    const checkLengthAndTrunc = (string) => {
      if (string.length + currStringLen > truncLength) {
        const truncString = string.substring(0, truncLength - currStringLen);
        truncated = true;
        currStringLen += truncString.length;
        return truncString;
      }
      currStringLen += string.length;
      return string;
    };

    const content = regions.reduce((acc, region, idx) => {
      const lastRegionNextIndex = region[1] + 1;
      const beforeHighlight = checkLengthAndTrunc(inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]));
      const highlight = checkLengthAndTrunc(inputText.substring(region[0], lastRegionNextIndex));

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;

      let afterHighlight;

      if (regions[idx + 1]) {
        afterHighlight = '';
      } else {
        afterHighlight = checkLengthAndTrunc(inputText.substring(nextUnhighlightedRegionStartingIndex));
      }

      acc.push(
        <span key={idx}>
          {beforeHighlight}
          <strong className={highlightClass}>
            {highlight}
          </strong>
          {afterHighlight}
        </span>
      );
      return acc;
    }, []);

    return truncated ? [...content, '...'] : content;
  };

  return fuseSearchResult ?
    fuseSearchResult.filter(({ matches }) => matches && matches.length)
      .map(({ item, matches }) => {
        const highlightedItem = { ...item };

        matches.forEach((match) => {
          highlightedItem[match.key] = generateHighlightedText(match.value, match.indices);
        });

        return { highlightedItem: highlightedItem, matches };
      }) : [];
}
