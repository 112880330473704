import 'stylesheets/application.scss';
import '../react/shared/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'jquery-modal';
import 'slick-carousel';

// Make React/Jquery Dependencies Global For Rails View Consumption
window.React = React;
window.ReactDOM = ReactDOM;
window.$ = $;
window.jQuery = $;

import '@/scripts';

import TopNavSearchForm from '@/react/topNavBar/TopNavSearchForm';
import TopNavHeader from '@/react/topNavBar/TopNavHeader';
import Popover from '@/react/shared/Popover';
import CompanyPicker from '@/react/CompanyPicker';
import InstallRequestButton from '@/react/InstallRequestButton';
import { ListingsIndex, ListingsShow } from '@/react/Listings';
import Index from '@/react/Index';

window.TopNavSearchForm = TopNavSearchForm;
window.TopNavHeader = TopNavHeader;
window.Popover = Popover;
window.CompanyPicker = CompanyPicker;
window.InstallRequestButton = InstallRequestButton;
window.ListingsIndex = ListingsIndex;
window.ListingsShow = ListingsShow;
window.Index = Index;
