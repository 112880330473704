import React from 'react';
import PT from 'prop-types';
import { Box, Button, H2, Modal, P } from '@procore/core-react';

export default class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { formSubmitted: false, modalOpen: false };
  }

  openContactModal = () => {
    if (!window.MktoForms2) {
      return;
    }
    this.props.sendAnalytics(
      'open',
      'Modal',
      'Contact Us',
      'contact_form.opened'
    );
    this.setState({
      modalOpen: true,
    });
    const { appName, email, company, developerAppId, contactUsEmail, createLead } = this.props;
    const ref = this;
    MktoForms2.loadForm(
      '//app-sj11.marketo.com',
      '788-HIQ-636',
      6597,
      (form) => {
        form.vals({
          Email: email,
          Company: company,
          Marketplace_Interest__c: appName,
          marketplaceDeveloperAppId: developerAppId,
          marketplacePartnerEmail: contactUsEmail,
          marketplaceProcoreBuiltApp: createLead,
        });

        form.onSuccess(() => {
          ref.setState({ formSubmitted: true });
          ref.props.sendAnalytics(
            'submit',
            'Form',
            'Contact Us',
            'contact_form.sent'
          );
          return false;
        });
      }
    );
  };

  closeContactModal = () => {
    this.setState({
      modalOpen: false,
    });

    const { formSubmitted } = this.state;

    this.props.sendAnalytics(
      'close',
      'Modal',
      'Contact Us',
      'contact_form.closed',
      { sent_form: formSubmitted ? 1 : 0 },
      formSubmitted ? 1 : 0
    );
  };

  render() {
    const { appName } = this.props;
    const { formSubmitted, modalOpen } = this.state;
    return (
      <>
        <Button variant="secondary" onClick={this.openContactModal}>
          Contact Us
        </Button>
        <Modal open={modalOpen} onClickOverlay={this.closeContactModal}>
          <Modal.Header onClose={this.closeContactModal}>
            Contact Us
          </Modal.Header>
          <Modal.Body style={{ paddingTop: 0 }}>
            <Box>
              {!formSubmitted ? (
                <>
                  <P id="mkto_start_form_p">
                    Please fill out the form below. A representative will be in
                    touch with you shortly with more information on pricing and
                    how to get started.
                  </P>
                </>
              ) : (
                <>
                  <Box>
                    <H2>Thank you!</H2>
                    <P>
                      {`Thanks for visiting our App Marketplace and requesting
                information on ${appName}.`}
                    </P>
                    <P>
                      We have received your request and a representative will
                      contact you shortly.
                    </P>
                  </Box>
                </>
              )}
              {!formSubmitted && <form id="mktoForm_6597" />}

              <P id="marketo-blocked" style={{ display: 'none' }}>
                If you are having issues viewing the form, please disable your
                Adblock.
              </P>
            </Box>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

ContactForm.propTypes = {
  appName: PT.string.isRequired,
  email: PT.string,
  company: PT.string,
  developerAppId: PT.string,
  sendAnalytics: PT.func,
};

ContactForm.defaultProps = {
  email: 'example@example.com',
  company: 'Company',
  developerAppId: '',
  sendAnalytics: () => {},
};
